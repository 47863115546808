/*
 *  Document   : _contactgrid.scss
 *  Author     : Dan&Peter
 *  Description: This scss file for style related to contact grid app
 */
.contact-grid {
  float: left;
  width: 100%;
  text-align: center;
}
.profile-header {
  min-height: 150px;
  color: #fff;
}
.user-name {
  padding: 3px;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
}
.user-img {
  padding: 3px;
  border-radius: 50% 50% 50% 50%;
  max-width: 112px;
  margin-top: -70px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}
.contact-grid .phone .material-icons {
  font-size: 16px;
  padding: 4px 7px 40px 0px;
}
