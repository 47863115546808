﻿/*
 *  Document   : _mediaobject.scss
 *  Author     : Dan&Peter
 *  Description: This scss file for media object style classes
 */
.media {
  margin-bottom: 25px;
  margin-top: 15px;

  .media-left {
    padding-right: 10px;
  }
  .media-body {
    color: #777;
    font-size: 13px;

    .media-heading {
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
  }
}
