﻿/*
 *  Document   : _buttons.scss
 *  Author     : Dan&Peter
 *  Description: This scss file for button style classes
 */
.example-button-row button,
.example-button-row a {
  margin-right: 8px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-space {
  margin-right: 10px !important;
}
.big-button {
  width: 200px !important;
  min-width: unset !important;
  height: 45px;
}
