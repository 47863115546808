﻿/*
 *  Document   : _thumbnail.scss
  *  Author     : Dan&Peter
 *  Description: This scss file for thumbnail style classes
 */
.thumbnail {
  @include border-radius(0);

  p:not(button) {
    color: #999999;
    font-size: 14px;
  }

  h3 {
    font-weight: bold;
    font-size: 17px;
  }
}
